<template>
  <div class="companies">
    <div
      v-for="(company, index) in companies"
      :id="`company-${index}`"
      :key="company.name"
      v-track-visibility
      class="companies__card"
    >
      <div class="companies__card-body">
        <h3 class="companies__card-title">{{ company.name }}</h3>
      </div>
      <div class="companies__card-footer">
        <div class="companies__links">
          <a
            v-if="company.address"
            :href="`http://www.google.com/search?q=${formattedAddress(company.address)}`"
            target="_blank"
            class="companies__link"
          >
            <t-icon
              height="16"
              width="16"
              :icon="addressIcon"
              class="companies__link--icon"
            />
            <span>{{ formattedAddress(company.address) }}</span>
          </a>
          <a
            v-if="company.email"
            :id="`company-${index}-email`"
            v-track-click
            :href="`mailto:${company.email}`"
            target="_blank"
            class="companies__link companies__link--email"
          >
            <t-icon
              height="16"
              width="16"
              :icon="emailIcon"
              class="companies__link--icon"
            />
            <span>{{ company.email }}</span>
          </a>
          <div class="companies__links-row">
            <a
              v-if="company.phone"
              :id="`company-${index}-phone`"
              v-track-click
              :href="`tel:${company.phone}`"
              target="_blank"
              class="companies__link companies__link--phone"
            >
              <t-icon
                height="16"
                width="16"
                :icon="phoneIcon"
                class="companies__link--icon"
              />
              <span>{{ company.phone }}</span>
            </a>
            <a
              v-if="company.website"
              :id="`company-${index}-website`"
              v-track-click
              :href="company.website"
              target="_blank"
              :title="`${company.website}`"
              class="companies__link"
            >
              <t-icon
                height="16"
                width="16"
                :icon="websiteIcon"
                class="companies__link--icon"
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mdiEmail, mdiMapMarker, mdiPhone, mdiWeb } from '@mdi/js'

export default {
  name: 'CompanyList',
  props: {
    companies: {
      type: Array,
      required: true,
    },
  },

  /**
   * @returns {{websiteIcon: string, phoneIcon: string, emailIcon: string, addressIcon: string}}
   */
  data() {
    return {
      addressIcon: mdiMapMarker,
      emailIcon: mdiEmail,
      phoneIcon: mdiPhone,
      websiteIcon: mdiWeb,
    }
  },
  methods: {
    /**
     * Formats the address.
     * @param {object} address - The address object.
     * @returns {string} - The formatted address.
     */
    formattedAddress(address) {
      const {
        address: street,
        'house-number': houseNumber,
        'house-number-addition': houseNumberAddition,
        postal,
        city,
      } = address
      return `${street} ${houseNumber}${houseNumberAddition || ''}, ${postal} ${city}`
    },
  },
}
</script>
