<template>
  <div class="page-thank-you">
    <div
      v-if="experiment && experiment.isTreatment"
      class="page-thank-you__header"
    >
      <div class="page-thank-you__content">
        <h1 class="page-thank-you__title">{{ $t('v2_title') }}</h1>

        <p class="page-thank-you__subtitle">
          {{ $t('v2_subtitle') }}
        </p>

        <div class="page-thank-you__info">
          <div class="page-thank-you__image">
            <img :src="image" width="280" height="280" :alt="$t('title')" />
          </div>
          <div class="page-thank-you__companies">
            <CompanyList :companies="companies" />
          </div>
        </div>

        <p class="page-thank-you__compare-text">
          <span class="compare-text__line">{{ $t('v2_compare_text_1') }}</span>
          <span class="compare-text__line">{{ $t('v2_compare_text_2') }}</span>
          <span class="compare-text__line">{{ $t('v2_compare_text_3') }}</span>
        </p>
      </div>
    </div>

    <div v-else class="thank-you container">
      <h1 class="thank-you__title">{{ $t('title') }}</h1>
      <p class="thank-you__body">{{ $t('subtitle') }}</p>
    </div>

    <div class="page-thank-you__container container">
      <div id="usps" class="usps usps__container">
        <h2 class="usps__title">{{ $t('tips_title') }}</h2>
        <Usps :usps="usps" />
      </div>
    </div>
  </div>
</template>

<script>
import Usps from 'chimera/all/themes/blueflow/components/Usps'
import AbstractPage from 'chimera/all/components/page/AbstractPage'
import { mdiEmail, mdiMapMarker, mdiPhone, mdiWeb } from '@mdi/js'
import CompanyList from 'chimera/all/themes/blueflow/page/ThankYou/CompanyList.vue'
import { ExperimentSource } from 'chimera/all/mixins/usesExperiments'

export default {
  components: {
    CompanyList,
    Usps,
  },

  extends: AbstractPage,

  /**
   * @returns {object}
   */
  data() {
    return {
      usps: [
        {
          text: this.$t('usps_1'),
          logo: require('chimera/all/themes/blueflow/images/usps/calendar.svg'),
        },
        {
          text: this.$t('usps_2'),
          logo: require('chimera/all/themes/blueflow/images/usps/offer.svg'),
        },
        {
          text: this.$t('usps_3'),
          logo: require('chimera/all/themes/blueflow/images/usps/safety.svg'),
        },
      ],
      address: mdiMapMarker,
      email: mdiEmail,
      phone: mdiPhone,
      website: mdiWeb,
      image: require('chimera/all/themes/blueflow/images/handshake.png'),
      companies: [],
      experiment: undefined,
      isExperimentInitializing: false,
    }
  },

  watch: {
    /**
     * @param {boolean} value
     */
    isExperimentInitializing(value) {
      if (value) {
        document.body.classList.add('experiments-init')
      } else {
        document.body.classList.remove('experiments-init')
      }
    },
  },

  /**
   *
   */
  async beforeMount() {
    this.isExperimentInitializing = true

    this.companies = this.$store.getters['lead/getResult']('companies', [])
    if (!this.companies.length) {
      this.experiment = {
        isTreatment: false,
      }

      this.isExperimentInitializing = false
      return
    }

    // https://bambelo.absmartly.com/experiments/365
    this.experiment = await this.$store.dispatch('experiments/get', {
      id: 'PD-7092',
      source: ExperimentSource.ABSMARTLY,
    })
    this.isExperimentInitializing = false
  },

  /**
   *
   */
  beforeDestroy() {
    this.isExperimentInitializing = false
  },
}
</script>

<i18n>
{
  "nl": {
    "title": "Hartelijk dank voor je aanvraag!",
    "subtitle": "De professionals nemen zo spoedig mogelijk contact met je op.",
    "tips_title": "Tips om even door te nemen",
    "usps_1": "Zorg dat je je telefoon en agenda bij de hand hebt, want professionals kunnen je telefonisch benaderen.",
    "usps_2": "Kijk niet alleen naar de prijs, maar vergelijk ook op kwaliteit, service en betrouwbaarheid.",
    "usps_3": "Neem rustig de tijd om voor de juiste professional te kiezen.",
    "v2_title": "We hebben met zorg de beste partners voor je geselecteerd!",
    "v2_subtitle": "Binnen enkele dagen nemen deze professionals contact met je op om je een persoonlijke offerte aan te bieden.",
    "v2_compare_text_1": "Vergelijk de aanbiedingen op je gemak en kies de optie die het beste bij je past.",
    "v2_compare_text_2": "Je kunt hun referenties eenvoudig controleren met de contactgegevens die wij hebben verstrekt.",
    "v2_compare_text_3": "Wij helpen je graag met het vinden van de juiste dienstverlener!"
  },
  "nl-BE": {
    "title": "Hartelijk dank voor uw aanvraag!",
    "subtitle": "De professionals nemen zo spoedig mogelijk contact met u op.",
    "tips_title": "Tips om even door te nemen",
    "usps_1": "Zorg dat u uw telefoon en agenda bij de hand hebt, want professionals kunnen u telefonisch benaderen.",
    "usps_2": "Kijk niet alleen naar de prijs, maar vergelijk ook op kwaliteit, service en betrouwbaarheid.",
    "usps_3": "Neem rustig de tijd om voor de juiste professional te kiezen.",
    "v2_title": "We hebben met zorg de beste partners voor u geselecteerd!",
    "v2_subtitle": "Binnen enkele dagen nemen deze professionals contact met u op om u een persoonlijke offerte aan te bieden.",
    "v2_compare_text_1": "Vergelijk de aanbiedingen op uw gemak en kies de optie die het beste bij u past.",
    "v2_compare_text_2": "U kunt hun referenties eenvoudig controleren met de contactgegevens die wij hebben verstrekt.",
    "v2_compare_text_3": "Wij helpen u graag met het vinden van de juiste dienstverlener!"
  },
  "es-ES": {
    "title": "¡Gracias por tu solicitud!",
    "subtitle": "Los profesionales se pondrán en contacto contigo lo antes posible.",
    "tips_title": "Consejos para revisar",
    "usps_1": "Asegúrate de tener tu teléfono y agenda a mano, ya que los profesionales pueden contactarte por teléfono.",
    "usps_2": "No mires solo el precio, sino también la calidad, el servicio y la fiabilidad.",
    "usps_3": "Tómate tu tiempo para elegir al profesional adecuado.",
    "v2_title": "¡Hemos seleccionado cuidadosamente los mejores socios para ti!",
    "v2_subtitle": "En unos días, estos profesionales se pondrán en contacto contigo para ofrecerte un presupuesto personalizado.",
    "v2_compare_text_1": "Compara las ofertas con tranquilidad y elige la opción que mejor se adapte a ti.",
    "v2_compare_text_2": "Puedes verificar fácilmente sus referencias con los datos de contacto que te hemos proporcionado.",
    "v2_compare_text_3": "¡Nos complace ayudarte a encontrar el proveedor de servicios adecuado!"
  },
  "de-DE": {
    "title": "Vielen Dank für Ihre Anfrage!",
    "subtitle": "Die Fachleute werden sich so schnell wie möglich mit Ihnen in Verbindung setzen.",
    "tips_title": "Tipps zum Durchsehen",
    "usps_1": "Stellen Sie sicher, dass Sie Ihr Telefon und Ihren Terminkalender zur Hand haben, da die Fachleute Sie telefonisch kontaktieren können.",
    "usps_2": "Achten Sie nicht nur auf den Preis, sondern vergleichen Sie auch Qualität, Service und Zuverlässigkeit.",
    "usps_3": "Nehmen Sie sich die Zeit, den richtigen Fachmann auszuwählen.",
    "v2_title": "Wir haben sorgfältig die besten Partner für Sie ausgewählt!",
    "v2_subtitle": "Innerhalb weniger Tage werden diese Fachleute Sie kontaktieren, um Ihnen ein persönliches Angebot zu unterbreiten.",
    "v2_compare_text_1": "Vergleichen Sie die Angebote in Ruhe und wählen Sie die Option, die am besten zu Ihnen passt.",
    "v2_compare_text_2": "Sie können ihre Referenzen einfach mit den von uns bereitgestellten Kontaktdaten überprüfen.",
    "v2_compare_text_3": "Wir helfen Ihnen gerne dabei, den richtigen Dienstleister zu finden!"
  },
  "fr-BE": {
    "title": "Merci pour votre demande!",
    "subtitle": "Ces professionnels vous contacteront dès que possible.",
    "tips_title": "Conseils à consulter",
    "usps_1": "Assurez-vous d'avoir votre téléphone et votre agenda à portée de main, car les professionnels peuvent vous contacter par téléphone.",
    "usps_2": "Ne regardez pas seulement le prix, mais comparez aussi la qualité, le service et la fiabilité.",
    "usps_3": "Prenez le temps de choisir le bon professionnel.",
    "v2_title": "Nous avons soigneusement sélectionné les meilleurs partenaires pour vous !",
    "v2_subtitle": "Ces professionnels vous contacteront dans quelques jours pour vous proposer un devis personnalisé.",
    "v2_compare_text_1": "Comparez les offres à votre rythme et choisissez l'option qui vous convient le mieux.",
    "v2_compare_text_2": "Vous pouvez facilement vérifier leurs références avec les coordonnées que nous vous avons fournies.",
    "v2_compare_text_3": "Nous sommes heureux de vous aider à trouver le bon prestataire !"
  },
  "fr-FR": {
    "title": "Merci pour votre demande!",
    "subtitle": "Les professionnels vous contacteront dès que possible.",
    "tips_title": "Conseils à consulter",
    "usps_1": "Assurez-vous d'avoir votre téléphone et votre agenda à portée de main, car les professionnels peuvent vous contacter par téléphone.",
    "usps_2": "Ne regardez pas seulement le prix, mais comparez aussi la qualité, le service et la fiabilité.",
    "usps_3": "Prenez le temps de choisir le bon professionnel.",
    "v2_title": "Nous avons soigneusement sélectionné les meilleurs partenaires pour vous !",
    "v2_subtitle": "Ces professionnels vous contacteront dans quelques jours pour vous proposer un devis personnalisé.",
    "v2_compare_text_1": "Comparez les offres à votre rythme et choisissez l'option qui vous convient le mieux.",
    "v2_compare_text_2": "Vous pouvez facilement vérifier leurs références avec les coordonnées que nous vous avons fournies.",
    "v2_compare_text_3": "Nous sommes heureux de vous aider à trouver le bon prestataire !"
  },
  "it-IT": {
    "title": "Grazie per la tua richiesta!",
    "subtitle": "Questi professionisti ti contatteranno il prima possibile.",
    "tips_title": "Suggerimenti da rivedere",
    "usps_1": "Assicurati di avere il telefono e l'agenda a portata di mano, poiché i professionisti potrebbero contattarti telefonicamente.",
    "usps_2": "Non guardare solo il prezzo, ma confronta anche qualità, servizio e affidabilità.",
    "usps_3": "Prenditi il tempo necessario per scegliere il giusto professionista.",
    "v2_title": "Abbiamo selezionato con cura i migliori partner per te!",
    "v2_subtitle": "Entro pochi giorni, questi professionisti ti contatteranno per offrirti un preventivo personalizzato.",
    "v2_compare_text_1": "Confronta le offerte con calma e scegli l'opzione più adatta a te.",
    "v2_compare_text_2": "Puoi verificare facilmente i loro riferimenti con i contatti che ti abbiamo fornito.",
    "v2_compare_text_3": "Siamo lieti di aiutarti a trovare il fornitore di servizi giusto!"
  }
}
</i18n>
